<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <div class="flex-row">
                    <h1>Вход</h1>
                    <CSpinner v-if="isLoading" color="success" size="sm"/>
                  </div>
                  <p v-if="error === null" class="text-muted">Войдите в аккаунт</p>
                  <p v-else class="text-danger">Неправильный логин или пароль</p>
                  <CInput
                      placeholder="Логин"
                      autocomplete="username email"
                      v-model="auth.login"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                      placeholder="Пароль"
                      type="password"
                      autocomplete="current-password"
                      v-model="auth.password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton @click="logUserIn" color="primary" class="px-4">Войти</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Забыли пароль?</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      auth: {
        login: null,
        password: null
      },
      error: null
    }
  },
  methods: {
    logUserIn() {
      this.$store.dispatch('security/login', this.auth).then(() => {
        if (!this.$store.getters[ 'security/hasError' ]) {
          this.$router.push({ path: '/' })
        } else {
          this.error = this.$store.getters['security/error']
        }
      }).catch(e => {
        this.error = e
      })
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters['security/isLoading']
    }
  }
}
</script>
